/**
 * 查询【工单详情】需要提交的source参数
 */
export enum AccessSource {
  Web = 'application-web', // 浏览器
  WebMobile = 'application-web-mobile', // 移动端浏览器
  SingleKf = 'application-kf-toolbar', // 微信客服-单聊模式
  Single = 'application-chat-toolbar', // 企微工作台-单聊模式
  Group = 'application-group', // 企微工作台-群聊模式
  Staff = 'application-stuffService', // 企微工作台-员工服务
  Attachment = 'application-attachment', // 聊天附件栏
  ChainGroup = 'chain-group', // 上下游群聊
}

/**
 * 建单来源
 */
export const CreationSource = {
  ...AccessSource,
  Wxkf: 'application-kf-svr', // 表示： 微信客服-后台自动建单
}

/**
 * 聊天模式
 */
export enum ChatMode {
  user = 'user',
  group = 'group',
  stuffService = 'stuffService', // 工作台、员工服务等
  attachment = 'attachment',
  chainGroup = 'chainGroup',
}

/**
 * 工单状态
 * 1: 待处理
 * 2: 处理中
 * 3: 待结单
 * 4: 已结单
 * 5: 建单信息待补充
 */
export enum OrderStatus {
  Pending = 1,
  Processing = 2,
  Closure = 3,
  Closed = 4,
  Creating = 5,
}

export enum OperationType {
  // OperationTypeCreate 建单
  OperationTypeCreate = 1,
  // OperationTypeView 访问
  OperationTypeView = 2,
  // OperationTypeAssign 派单
  OperationTypeAssign = 3,
  // OperationTypeUpdateProcess 更新进展
  OperationTypeUpdateProcess = 4,
  // OperationTypeApplyClose 申请结单
  OperationTypeApplyClose = 5,
  // OperationTypeClose 结单
  OperationTypeClose = 6,
  // OperationTypeRefuseClose 拒绝结单
  OperationTypeRefuseClose = 7,
  // OperationTypeTransfer 转单
  OperationTypeTransfer = 8,
  // OperationTypeOLA OLA告警
  OperationTypeOLA = 9,
  // OperationTypeComment 评论
  OperationTypeComment = 10,
  // OperationTypeAutoFinished 自动结单
  OperationTypeAutoFinished = 11,
  // OperationTypeRemind 催单
  OperationTypeRemind = 12,
  // OperationTypeUrgeAlarm 催单告警
  OperationTypeUrgeAlarm = 13,
  // OperationTypeClaim 认领
  OperationTypeClaim = 14,
  // OperationTypeDelete 删除事件单
  OperationTypeDelete = 15,
  // 直接修改模板的值
  EditTemplateValue = 16,
  // 评价（内部）
  Review = 17,
  // 补充字段完成建单
  CompleteCreation = 18,
}

/**
 * 一些通用全局变量，不区分环境
 * systemID：用于作对比的系统ID
 * systemAvatar：某些特殊情况显示的系统头像，比如流水
 * systemName: 某些特殊情况显示的系统名称，比如流水
 * defaultAvatar：默认头像，由于后端没返回头像的情况
 */
export const system = {
  systemName: '系统',
  systemID: 'SYSTEM',
  systemAvatar: 'https://andonplus-1258344699.cos.ap-guangzhou.myqcloud.com/resource/andonplus-logo.png',
  defaultAvatar: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHgAAAB4CAMAAAAOusbgAAAAbFBMVEXd3d3e3t7f39/g4ODh4eHi4uLj4+Pk5OTk5unl5eXm5ubn5+fo6Ojp6enq6urr6+vs7Ozt7e3u7u7v7+/w8PDx8fHy8vLz8/P09PT19fX29vb39/f4+Pj5+fn6+vr7+/v8/Pz9/f3+/v7///8Fdw+DAAACEklEQVRo3u3bbXKCMBCA4c021IogVgEpFol4/zv2X2c6Qj53Q2vzXuAZQYFdRnhbKVgRvq9QghOc4AQnOMEJTrBH/XEnEeXu2MeEx8MLfPdyGCPBai/gR2KvYsBnhIfwzA9XMFvFDS+4rrIzXMNiNSc84jKMIyNcgKaCD56EDhYTG9yBto4NrvRwxQbnejhng6UellzwBIYmJngwwQMT/GGCP5jg1gS3TPDJBJ+Y4IMJPjwbXJng6tng1Q71at/qswk+M8G9Ce6Z4JsJvnHdFjO9m7Hdj/d6eP98z1wT6lzke8rUH2uHI+0MX3TwhXN22i67W9ahrae4eniNqZsld8M8H18ozrDXRqCcd0v2VYSa/S2jYofvxzn4GGHrM87BYwR4bnST9xjw7hHeRYHL4O+0J1wFr9f+2id+fYRfY8BqZuckVAS4Dt4nesIycO/iC7ehywBPeGmNiiMvrBaf6TPFCV81s0R25YMb/XN1wwR/bk1D2/aTAR5KsKgciOGhAMuKgRB+eMWlXdTbvP6ygqd3BKfwfaKAOwnOyS4YHnPwKh/D4BrBM6wD4OsGAtpcfeEGISjNpUwH3woIrri5w0MGBGWDK9whkISdG9wIIEo0LnALhLX2sEJKeG52XoBPQFptDee0cG4NS1pYWsOCFhbWMBD3++HVznFPKsueaDCnKMEJTnCCE5zgBP93+J/9i+8LmPKq0dXXgKwAAAAASUVORK5CYII=',
};

/**
 * 环境变量配置
 * cosBucket: cos资源bucket
 * cosRegion: cos资源region
 * */
export const env = {
  dev: {
    cosBucket: 'andon-saas-dev-1258344699',
    cosRegion: 'ap-nanjing',
  },
  test: {
    cosBucket: 'andon-saas-dev-1258344699',
    cosRegion: 'ap-nanjing',
  },
  prod: {
    cosBucket: 'andon-saas-1258344699',
    cosRegion: 'ap-guangzhou',
  },
};

export enum StatusEnum {
  enable = 0,
  disabled = 1
}

export enum DirLevel {
  first = 1,
  second = 2,
  third = 3,
}

// 布局类型
export const SHOW_TYPE_ENUMS = {
  LEFT: 'left',
  CENTER: 'center',
};

export const RELATION_VALUES = {
  OPERATION: 'operation',
  OLA: 'ola',
  RECORD: 'record',
  FIELD: 'field',
};

export const KEYS = {
  // 每条流水数据的类型
  dataTypeKey: 'OperationType',
};

export const FLOW_TYPE_ENUMS = {
  CREATED: 1,
  RECORD: 2,
  DISPATCH_TICKET: 3,
  UPDATED: 4,
  APPLY_CLOSE: 5,
  AGREE_CLOSE: 6,
  REFUSED_CLOSE: 7,
  TRANSFORM_TICKET: 8,
  OLA: 9,
  COMMENT: 10,
  AUTO_CLOSE: 11,
  REMIND: 12,
  REMIND_ALERT: 13,
  CLAIM: 14,
  EDIT_TEMPLATE_VALUE: OperationType.EditTemplateValue,
  REVIEW: OperationType.Review,
  COMPLETE_CREATION: OperationType.CompleteCreation,
};

/**
 * 流水类型（根据以下枚举获取流水信息）：
 * 操作记录
 * OLA记录
 * 字段记录(仅前端展示使用)
 * 访问记录
 */
export const RELATION_VALUES_MAP = {
  operation: [
    FLOW_TYPE_ENUMS.CREATED,
    FLOW_TYPE_ENUMS.DISPATCH_TICKET,
    FLOW_TYPE_ENUMS.UPDATED,
    FLOW_TYPE_ENUMS.APPLY_CLOSE,
    FLOW_TYPE_ENUMS.AGREE_CLOSE,
    FLOW_TYPE_ENUMS.REFUSED_CLOSE,
    FLOW_TYPE_ENUMS.TRANSFORM_TICKET,
    FLOW_TYPE_ENUMS.COMMENT,
    FLOW_TYPE_ENUMS.AUTO_CLOSE,
    FLOW_TYPE_ENUMS.REMIND,
    FLOW_TYPE_ENUMS.CLAIM,
    FLOW_TYPE_ENUMS.EDIT_TEMPLATE_VALUE,
    FLOW_TYPE_ENUMS.REVIEW,
    FLOW_TYPE_ENUMS.COMPLETE_CREATION,
  ],
  ola: [FLOW_TYPE_ENUMS.OLA, FLOW_TYPE_ENUMS.REMIND_ALERT],
  field: [],
  record: [FLOW_TYPE_ENUMS.RECORD],
};

/**
 * 定义流水如何渲染
 * value: 流水类型，对应流水数据中${KEYS.dataTypeKey}值
 * relationValue: 关联值, 当需要对比两者关系[props.relationValue]?.includes(${relationValue})
 * showType: 渲染布局关系
 * */
export const FLOW_RENDER_PROPS_MAPS = {
  [FLOW_TYPE_ENUMS.CREATED]: {
    // 建单
    value: FLOW_TYPE_ENUMS.CREATED,
    relationValue: RELATION_VALUES.OPERATION,
    showType: SHOW_TYPE_ENUMS.LEFT,
  },
  [FLOW_TYPE_ENUMS.RECORD]: {
    // 访问
    value: FLOW_TYPE_ENUMS.RECORD,
    relationValue: RELATION_VALUES.RECORD,
    showType: SHOW_TYPE_ENUMS.CENTER,
  },
  [FLOW_TYPE_ENUMS.DISPATCH_TICKET]: {
    // 派单
    value: FLOW_TYPE_ENUMS.DISPATCH_TICKET,
    relationValue: RELATION_VALUES.OPERATION,
    showType: SHOW_TYPE_ENUMS.CENTER,
  },
  [FLOW_TYPE_ENUMS.UPDATED]: {
    // 更新进展
    value: FLOW_TYPE_ENUMS.UPDATED,
    relationValue: RELATION_VALUES.OPERATION,
    showType: SHOW_TYPE_ENUMS.LEFT,
  },
  [FLOW_TYPE_ENUMS.APPLY_CLOSE]: {
    // 申请结单
    value: FLOW_TYPE_ENUMS.APPLY_CLOSE,
    relationValue: RELATION_VALUES.OPERATION,
    showType: SHOW_TYPE_ENUMS.LEFT,
  },
  [FLOW_TYPE_ENUMS.AGREE_CLOSE]: {
    // 结单
    value: FLOW_TYPE_ENUMS.AGREE_CLOSE,
    relationValue: RELATION_VALUES.OPERATION,
    showType: SHOW_TYPE_ENUMS.LEFT,
  },
  [FLOW_TYPE_ENUMS.REFUSED_CLOSE]: {
    // 拒绝结单
    value: FLOW_TYPE_ENUMS.REFUSED_CLOSE,
    relationValue: RELATION_VALUES.OPERATION,
    showType: SHOW_TYPE_ENUMS.LEFT,
  },
  [FLOW_TYPE_ENUMS.TRANSFORM_TICKET]: {
    // 转单
    value: FLOW_TYPE_ENUMS.TRANSFORM_TICKET,
    relationValue: RELATION_VALUES.OPERATION,
    showType: SHOW_TYPE_ENUMS.LEFT,
  },
  [FLOW_TYPE_ENUMS.OLA]: {
    // OLA告警
    value: FLOW_TYPE_ENUMS.OLA,
    relationValue: RELATION_VALUES.OLA,
    showType: SHOW_TYPE_ENUMS.CENTER,
  },
  [FLOW_TYPE_ENUMS.COMMENT]: {
    // 评论
    value: FLOW_TYPE_ENUMS.COMMENT,
    relationValue: RELATION_VALUES.OPERATION,
    showType: SHOW_TYPE_ENUMS.LEFT,
  },
  [FLOW_TYPE_ENUMS.AUTO_CLOSE]: {
    // 自动结单
    value: FLOW_TYPE_ENUMS.AUTO_CLOSE,
    relationValue: RELATION_VALUES.OPERATION,
    showType: SHOW_TYPE_ENUMS.LEFT,
  },
  [FLOW_TYPE_ENUMS.REMIND]: {
    // 催单
    value: FLOW_TYPE_ENUMS.REMIND,
    relationValue: RELATION_VALUES.OPERATION,
    showType: SHOW_TYPE_ENUMS.LEFT,
  },
  [FLOW_TYPE_ENUMS.REMIND_ALERT]: {
    // 催单告警
    value: FLOW_TYPE_ENUMS.REMIND_ALERT,
    relationValue: RELATION_VALUES.OLA,
    showType: SHOW_TYPE_ENUMS.CENTER,
  },
  [FLOW_TYPE_ENUMS.CLAIM]: {
    // 认领
    value: FLOW_TYPE_ENUMS.CLAIM,
    relationValue: RELATION_VALUES.OPERATION,
    showType: SHOW_TYPE_ENUMS.LEFT,
  },
  [FLOW_TYPE_ENUMS.EDIT_TEMPLATE_VALUE]: {
    value: FLOW_TYPE_ENUMS.EDIT_TEMPLATE_VALUE,
    relationValue: RELATION_VALUES.OPERATION,
    showType: SHOW_TYPE_ENUMS.LEFT,
  },
  [FLOW_TYPE_ENUMS.REVIEW]: {
    value: FLOW_TYPE_ENUMS.REVIEW,
    relationValue: RELATION_VALUES.OPERATION,
    showType: SHOW_TYPE_ENUMS.LEFT,
  },
  [FLOW_TYPE_ENUMS.COMPLETE_CREATION]: {
    value: FLOW_TYPE_ENUMS.COMPLETE_CREATION,
    relationValue: RELATION_VALUES.OPERATION,
    showType: SHOW_TYPE_ENUMS.LEFT,
  },
};

export const RELATION_APP_TYPE_MAP = {
  1: 'CODING',
  2: 'TAPD',
};

export const RELATION_APP_TYPE = {
  CODING: 1,
  TAPD: 2,
  KNOWLEDGE: 3,
  SERVICE360: 4,
};

export const APP_AUTH_STATUS = {
  AUTHORIZED: 1,
  UNAUTHORIZED: 2,
};

export const RELATION_PROJECT_RANGE = {
  RELATION: 1,
  ALL: 2,
};

export const KNOWLEDGE_STATUS = {
  UNORGANIZED: 1,
  ORGANIZED: 2,
};

export const KNOWLEDGE_TYPE_MAP = {
  0: '知识库',
  1: '工单',
};

export const KNOWLEDGE_TYPE = {
  KNOWLEDGE: 0,
  TICKET: 1,
};
