import Cos from 'cos-js-sdk-v5';
import { reportCommonEvent } from '@shared/tandon-plugin/aegis';
import { AsyncWaitCallback } from './async-wait-callback';
import {
  GetStsKeysType,
  CosAuthorizationInterface,
  SliceUploadFileParamsInterface,
  GetUrlParamsInterface,
  GetRelativeUrlInterface,
  getUploadHeadersInterface,
} from './cos-uploader-types';

const emptyCosParamsTips = '无效的cos初始化信息，请检查getStsKeys设置';
const undefinedCosInstance = '未初始化cosInstance';
const FORMAT_TYPES_REG = /heif$|heic$|dng$/;
/**
 * api尽量保持cos-sdk一致
 * 提前设置密钥获取回调
 *
 * 执行任何cos-sdk操作前，均检查密钥过期，并通过promise等待密钥与操作结果
 */
export default class Index {
  private asyncWaitCallback: AsyncWaitCallback;

  private expiredTime: number;

  private bucket: string;

  private region: string;

  private getStsKeys: GetStsKeysType;

  private cosInstance: Cos | undefined;

  public constructor() {
    this.asyncWaitCallback = new AsyncWaitCallback();
    this.expiredTime = 0;
    this.bucket = '';
    this.region = '';

    this.getStsKeys = () => Promise.resolve({
      bucket: '',
      region: '',
      expiredTime: 0,
      credentials: {
        tmpSecretId: '',
        tmpSecretKey: '',
        sessionToken: '',
      },
      startTime: 0,
    });
  }

  public setStsKeys(getStsKeys: GetStsKeysType): Index {
    this.getStsKeys = getStsKeys;
    return this;
  }

  private getUploadHeaders = ({ fileKey, fileType }: getUploadHeadersInterface) => {
    const headers = {};
    if (fileType === 'txt') {
      // @ts-ignore
      headers['Content-Type'] = 'text/plain; charset=utf-8';
    }
    const picOperationsRules = [];
    // 图片格式转换/图片上传移除 exif
    if (FORMAT_TYPES_REG.test(fileType)) {
      picOperationsRules.push({
        fileid: fileKey,
        rule: 'imageMogr2/format/png',
      });
    }
    if (picOperationsRules.length) {
      // @ts-ignore
      headers['Pic-Operations'] = JSON.stringify({
        is_pic_info: 1,
        rules: picOperationsRules,
      });
    }
    return headers;
  };

  /**
   * 每次操作检查是否过期，过期重新获取临时密钥
   * 同时操作需要都需要等待第一次临时密钥返回，使用asyncWaitCallback解决
   */
  public checkExpired(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.getNow().then((now) => {
        if (this.expiredTime < now) {
          this.asyncWaitCallback.setChangeStatus(() => new Promise<void>((changeStatusResolve, changeStatusReject) => {
            reportCommonEvent({
              event: 'COS-UPLOADER: GET READ KEY',
              data: {},
            });
            this.getStsKeys()
              .then((cosParams: CosAuthorizationInterface) => {
                this.updateCOSInstance({
                  ...cosParams,
                  startTime: now,
                });
                changeStatusResolve();
              })
              .catch(e => changeStatusReject(e));
          }));
        }
        this.asyncWaitCallback.onEndCallback(resolve, reject);
      });
    });
  }

  public async sliceUploadFile({
    key,
    body,
    fileType,
    onHashProgress,
    onProgress,
  }: SliceUploadFileParamsInterface): Promise<Cos.SliceUploadFileResult> {
    reportCommonEvent({
      event: 'COS-UPLOADER: PREPARE UPLOAD',
      data: { fileName: key, fileType },
    });
    // 原先可以复用密钥进行上传，后来出现安全工单，特此改为一次性密钥上传
    const reg = /^(\/[^/]+\/)(.+)$/;
    const [_match, _tenant, relativePath] = reg.exec(key) || [];
    if (!relativePath) throw new Error('文件路径为空');

    const params = '?'
      .concat('UploadFileName=')
      .concat(encodeURIComponent(relativePath));

    reportCommonEvent({
      event: 'COS-UPLOADER: GET WRITE KEY',
      data: { params },
    });
    const cosInfo = await this.getStsKeys(params);
    if (!cosInfo.bucket || !cosInfo.region || cosInfo.expiredTime === 0 || !cosInfo.credentials) throw new Error(emptyCosParamsTips);

    const now = await this.getNow();
    const instance = new Cos({
      getAuthorization(_options, callback: Function) {
        callback({
          TmpSecretId: cosInfo.credentials.tmpSecretId,
          TmpSecretKey: cosInfo.credentials.tmpSecretKey,
          XCosSecurityToken: cosInfo.credentials.sessionToken,
          StartTime: now,
          ExpiredTime: cosInfo.expiredTime,
        });
      },
    });

    return new Promise((resolve, reject) => {
      instance.sliceUploadFile(
        {
          Bucket: cosInfo.bucket,
          Region: cosInfo.region,
          Key: key,
          Body: body,
          Headers: this.getUploadHeaders({ fileKey: key, fileType }),
          onHashProgress,
          onProgress,
        },
        (err, data) => {
          reportCommonEvent({
            event: 'COS-UPLOADER: RESULT',
            data: { err, data },
          });
          if (err) reject(err);
          else resolve(data);
        },
      );
    });
    // return new Promise((resolve, reject) => {
    //   this.checkExpired()
    //     .then(() => {
    //       const headers = this.getUploadHeaders({ fileKey: key, fileType });
    //       if (this.cosInstance) {
    //         this.cosInstance.sliceUploadFile(
    //           {
    //             Bucket: this.bucket,
    //             Region: this.region,
    //             Key: key,
    //             Body: body,
    //             Headers: headers,
    //             onHashProgress,
    //             onProgress,
    //           },
    //           (err, data) => {
    //             if (err) reject(err);
    //             else resolve(data);
    //           },
    //         );
    //       } else {
    //         reject(new Error(undefinedCosInstance));
    //       }
    //     })
    //     .catch(reject);
    // });
  }

  public getUrl({ key, sign }: GetUrlParamsInterface): Promise<Cos.GetObjectUrlResult> {
    return new Promise((resolve, reject) => {
      this.checkExpired()
        .then(() => {
          if (this.cosInstance) {
            this.cosInstance.getObjectUrl(
              {
                Bucket: this.bucket,
                Region: this.region,
                Key: key,
                Sign: sign,
              },
              (err, data) => {
                if (err) reject(err);
                else resolve(data);
              },
            );
          } else {
            reject(new Error(undefinedCosInstance));
          }
        })
        .catch(reject);
    });
  }

  public getRelativeUrl({ key }: GetRelativeUrlInterface): Promise<string> {
    return new Promise((resolve, reject) => {
      this.getUrl({ key, sign: false }).then(({ Url }) => resolve(Url))
        .catch(reject);
    });
  }

  private updateCOSInstance(cosParams: CosAuthorizationInterface): void {
    if (!cosParams.bucket || !cosParams.region || cosParams.expiredTime === 0 || !cosParams.credentials) {
      throw new Error(emptyCosParamsTips);
    }
    this.bucket = cosParams.bucket;
    this.region = cosParams.region;
    // 过期时间转换为毫秒
    this.expiredTime = cosParams.expiredTime;
    this.cosInstance = new Cos({
      getAuthorization(options, callback: Function) {
        callback({
          StartTime: cosParams.startTime,
          TmpSecretId: cosParams.credentials.tmpSecretId,
          TmpSecretKey: cosParams.credentials.tmpSecretKey,
          XCosSecurityToken: cosParams.credentials.sessionToken,
          ExpiredTime: cosParams.expiredTime,
        });
      },
    });
  }

  // eslint-disable-next-line class-methods-use-this
  private getNow(): Promise<number> {
    return new Promise((resolve) => {
      const localNow = Math.floor(Date.now() / 1000);
      resolve(localNow);
      // if (typeof window?.fetch === 'function') {
      //   window?.fetch?.('https://service-o5mkcqjm-1258344699.gz.apigw.tencentcs.com/gettime', {
      //     method: 'get',
      //   }).then(res => res.json())
      //     .then((data) => {
      //       if (data.code === 0) {
      //         resolve(data.data);
      //       } else {
      //         resolve(localNow);
      //       }
      //     })
      //     .catch(() => {
      //       resolve(localNow);
      //     });
      // } else {
      //   resolve(localNow);
      // }
    });
  }
}
