import { get } from '@shared/tandon-plugin/request';
import { reportCommonEvent } from '@shared/tandon-plugin/aegis';

export interface CosAuthorizationInterface {
  bucket: string;
  region: string;
  expiredTime: number;
  credentials: CosCredentialsInterface;
  startTime: number;
}

export interface CosCredentialsInterface {
  tmpSecretId: string;
  tmpSecretKey: string;
  sessionToken: string;
}

export interface ICosSts {
  bucket: string
  region: string
}

const getCosConfig = (env: string) => {
  if (env === 'prod') {
    return {
      bucket: 'andon-saas-1258344699',
      region: 'ap-guangzhou',
      domain: 'filecache.andon.tencent.com',
    };
  }
  return {
    bucket: 'andon-saas-dev-1258344699',
    region: 'ap-nanjing',
    domain: 'filecache.dev-andon.tencent.com',
  };
};

/**
 * 获取COS token
 * @wiki http://doc.tandon.woa.com/project/37/interface/api/1061
 */
export const getCosSts = async (env: string, params = ''): Promise<CosAuthorizationInterface> => {
  reportCommonEvent({
    event: 'GET COS STS',
    data: { params },
  });

  const { bucket, region } = getCosConfig(env);

  const data = await get(`/api/v1/auth/cos/sts${params}`);
  if (data && data.Credentials) {
    return {
      bucket: bucket || '',
      region: region || '',
      startTime: data.StartTime,
      expiredTime: data.ExpiredTime, // 秒
      credentials: {
        tmpSecretId: data.Credentials.TmpSecretID,
        tmpSecretKey: data.Credentials.TmpSecretKey,
        sessionToken: data.Credentials.SessionToken,
      },
    };
  }

  if (data.Error && data.Error?.Code === 'RequestLimitExceeded') throw {
    code: data.Error.Code,
    message: '超出当天可上传图片或附件的最大次数',
    origin: data.Error,
  };

  return {
    bucket: '',
    region: '',
    startTime: Date.now(),
    expiredTime: 1000, // 秒
    credentials: {
      tmpSecretId: '',
      tmpSecretKey: '',
      sessionToken: '',
    },
  };
};

// 获取cos域名链接
export const getAndonPlusCosDomainUrl = (url: string, env: string) => {
  if (!url) return '';
  const { domain } = getCosConfig(env);
  const urlReg = /^(https?:\/\/)[a-zA-Z0-9][-a-zA-Z0-9]{0,62}(\.[a-zA-Z0-9][-a-zA-Z0-9]{0,62})+\.?/;
  return url.replace(urlReg, `https://${domain}`);
};
