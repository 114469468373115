/* eslint-disable consistent-return */
import React, { useEffect, useState } from 'react';
import { Editor, Toolbar } from '@wangeditor/editor-for-react';
import { Boot, IDomEditor, IToolbarConfig } from '@wangeditor/editor';
import { getWeworkTenantId } from '@shared/tandon-plugin/token';
import '@wangeditor/editor/dist/css/style.css';
import { Loading } from 'tdesign-react';
import { MessageError } from '@shared/tandon-design/message-error';
import { reportCommonEvent } from '@shared/tandon-plugin/aegis';
import attachmentModule from './plugins/upload-attachment';
import COSUploader from '../cos-uploader';
import { getAndonPlusCosDomainUrl, getCosSts } from '../cos-uploader/utils';
import { useTyping } from './interface';

export interface RichEditorProps {
  isMobile?: boolean;
  value?: string;
  placeholder?: string
  runtimeEnv: string
  customToolbarConfig?: any
  style?: React.CSSProperties;
  onChange?: (html: string) => void
  onUploading: (isUploading: boolean) => void;
}

const uuid = (): string => (+new Date() * 1e6 + Math.floor(Math.random() * 1e6))
  .toString(36)
  .toUpperCase();

declare global {
  interface Window {
    registeredModules: string[];
    wangEditorText: string;
  }
}

type InsertFnType = (url: string, alt: string, href: string) => void;

const imageTypes = [
  'ico',
  'png',
  'jpeg',
  'jpg',
  'gif',
  'bmp',
  'dng',
  'heif',
  'heic',
];

function RichEditor(props: RichEditorProps): React.ReactElement {
  const { isMobile = false, value, placeholder, runtimeEnv = 'prod', customToolbarConfig, onChange, onUploading } = props;
  const [editor, setEditor] = useState<IDomEditor | null>(null);
  const [cosUploader, setCosUploader] = useState<COSUploader>();
  const [isUploading, setIsUploading] = useState(false);
  const isTyping = useTyping();
  window.registeredModules = window.registeredModules || [];

  const initCosUploader = () => {
    if (cosUploader) {
      return cosUploader;
    }
    const cos = new COSUploader();
    cos.setStsKeys((params = '') => getCosSts(runtimeEnv, params));
    setCosUploader(cos);
    return cos;
  };

  const customUpload = async (file: File, insertFn: InsertFnType) => {
    const fileSize = file.size;
    if (fileSize > 2 * 1024 * 1024 * 1024) {
      MessageError('上传文件大小超过限制，目前仅支持上传大小不超过2G的附件，请重新选择附件上传。');
      return;
    }
    const cosUploader = initCosUploader();
    const fileName = `${uuid()}_${file.name}`;
    const fileNameArray = file.name.split('.');
    const fileType = fileNameArray[fileNameArray.length - 1].toLowerCase();
    const filePath = `/${getWeworkTenantId() || 'default'}/${fileName}`;
    try {
      setIsUploading(true);
      await cosUploader.sliceUploadFile({
        key: filePath,
        body: file,
        fileType,
      });
      reportCommonEvent({
        event: 'COS UPLOAD SLICE SUCCESS',
        data: {
          fileName,
        },
      });
      const res = await cosUploader.getRelativeUrl({
        key: filePath,
      });
      const url = getAndonPlusCosDomainUrl(res, runtimeEnv);
      if (url) {
        reportCommonEvent({
          event: 'COS UPLOAD GET URL SUCCESS',
          data: {
            res,
          },
        });
        insertFn(url || '', fileName || '', filePath || '');
      } else {
        reportCommonEvent({
          event: 'COS UPLOAD GET URL FAIL',
          data: {
            res,
          },
        });
        MessageError('上传失败，请刷新页面重试');
      }
      setIsUploading(false);
    } catch (error: any) {
      setIsUploading(false);
      reportCommonEvent({
        event: 'COS UPLOAD ERROR',
        data: {
          cosUploader,
          error,
        },
      });
      MessageError(error?.message || '上传失败，请刷新页面重试');
    }
  };

  const onPasteUpload = async (blob: Blob) => {
    const fileName = `paste_${uuid()}.${blob.type.split('/')[1]}`;
    const cosUploader = initCosUploader();
    cosUploader.setStsKeys((params = '') => getCosSts(runtimeEnv, params));
    const filePath = `/${getWeworkTenantId() || 'default'}/${fileName}`;
    try {
      setIsUploading(true);
      await cosUploader.sliceUploadFile({
        key: filePath,
        body: blob,
        fileType: blob.type,
      });
      const res = await cosUploader.getRelativeUrl({
        key: filePath,
      });
      const url = getAndonPlusCosDomainUrl(res, runtimeEnv);
      setIsUploading(false);
      reportCommonEvent({
        event: 'COS UPLOAD PASTE SUCCESS',
        data: {
          res,
        },
      });
      return url || '';
    } catch (error) {
      reportCommonEvent({
        event: 'COS UPLOAD PASTE ERROR',
        data: {
          error,
        },
      });
      setIsUploading(false);
      MessageError(error?.message || '上传失败，请刷新页面重试');
      return '';
    }
  };

  // 工具栏配置
  const toolbarConfig: Partial<IToolbarConfig> = customToolbarConfig || {
    toolbarKeys: [
      'emotion',
      'uploadImage',
      'undo',
      'redo',
    ],
    insertKeys: {
      index: 2,
      keys: ['uploadAttachment'],
    },
  };

  // 编辑器配置
  const editorConfig = {
    placeholder: placeholder || '请输入内容...',
    autoFocus: false,
    MENU_CONF: {
      uploadImage: {
        allowedFileTypes: ['image/*,.dng,.heif,.heic,.bmp'],
        customUpload: (file: File, insertFn: InsertFnType) => {
          const fileNameArray = file.name.split('.');
          const fileType = fileNameArray[fileNameArray.length - 1].toLowerCase();
          if (!imageTypes?.includes(fileType)) {
            MessageError('图片格式不支持，请选择附件上传');
            return;
          }
          customUpload(file, insertFn);
        },
      },
    },
    CUSTOM_CONF: {
      uploadAttachment: {
        customUpload,
      },
    },
  };

  useEffect(() => {
    if (editor && !window.registeredModules?.includes('attachmentModule')) {
      Boot.registerModule(attachmentModule);
      window.registeredModules.push('attachmentModule');
    }
    return () => {
      if (editor === null) return;
      editor.destroy();
      setEditor(null);
    };
  }, [editor]);

  const onFocusEditor = () => {
    if (editor && !editor.isFocused() && !isTyping) {
      editor.focus();
    }
  };

  const onChangeEditor = (editor: IDomEditor) => {
    const html = editor.getHtml();
    const text = editor.getText();
    const pasteImageUrl = html.match(/<img src="(blob:[^"]+)"/)?.[1];
    // 兼容企业微信图片复制粘贴
    if (html && fetch && pasteImageUrl?.startsWith('blob:')) {
      fetch(pasteImageUrl).then(res => res.blob())
        .then(async (blob: Blob) => {
          if (!imageTypes.includes(blob.type.split('/')[1])) {
            editor?.undo?.();
            return MessageError('图片格式不支持，请选择附件上传');
          }
          const url = await onPasteUpload(blob);
          const newHtml = html.replace(pasteImageUrl, url);
          onChange?.(newHtml);
        });
    } else {
      onChange?.(html);
    }
    window.wangEditorText = text;
  };

  useEffect(() => {
    onUploading(isUploading);
  }, [isUploading]);

  return (
    <div style={{ border: '1px solid #ccc', zIndex: 100, width: '100%' }}>
      <Loading size="small" loading={isUploading} showOverlay text="正在上传中...">
        <div onMouseEnter={onFocusEditor}>
          <Toolbar
            editor={editor}
            defaultConfig={toolbarConfig}
            mode="default"
            style={{ borderBottom: '1px solid #ccc' }}
          />
        </div>
        <Editor
          defaultConfig={editorConfig}
          value={value}
          onCreated={setEditor}
          onChange={onChangeEditor}
          mode="simple"
          style={{ height: isMobile ? '200px' : '280px', overflowY: 'hidden', ...(props.style || {}) }}
        />
      </Loading>
    </div>
  );
}

export default RichEditor;
